
import api from '@/utilities/api'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { headers, items } from '@/pages/bulkUpload/helpers'
import { DateTime } from 'luxon'
import { DEFAULT_DAY_RANGES } from '@/utilities/constants'
import cloneDeep from 'lodash/cloneDeep'

const { now } = DateTime

@Component({})
export default class BulkUploadPage extends Vue {
  // data // ***
  modalOpen = false
  dates: DateTime[] = [now().minus({ days: DEFAULT_DAY_RANGES.BULK_UPLOAD }), now()]

  // computed // ***
  get actionHeader() {
    return { vueComponent: { name: 'c-loading-spinner', props: { class: 'ma-2', color: 'error' } } }
  }
  get headers() {
    return headers(this)
  }
  get bulkUploads() {
    return this.$store.getters['suppliers/bulkUploads'] || []
  }
  get fetching(): boolean {
    const { fetch: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'GET', url })
  }
  get items(): Record<string, any>[] {
    return items(this)
  }
  get supplier(): Record<string, any> {
    return this.$store.getters['suppliers/selectedSupplier']
  }
  get uploadDialogTitle(): string {
    const x = `${this.$t('cmac.journeys')} ${this.$t('ui.spreadsheet')}`
    return String(this.$t('ui.uploadX', { x }))
  }
  get queryParams() {
    const { dates } = this
    const dateFrom = dates[0].toISODate()
    const dateTo = this.dates[1]?.toISODate() || cloneDeep(dates[0]).plus({ days: 1 }).toISODate()
    return {
      dateFrom,
      dateTo,
      organisationId: String(this.supplier?.id),
    }
  }
  get uploading(): boolean {
    const { upload: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'POST', url })
  }
  get urls(): Record<string, string> {
    return {
      fetch: 'supplier/costConfirmationJourneys/uploads',
      upload: '/supplier/costConfirmationJourneys/upload',
    }
  }

  // mounted // ***
  mounted() {
    this.fetch()
  }

  // watch // ***
  @Watch('supplier')
  onSupplierChanged(n: Record<string, any> | undefined, o: Record<string, any> | undefined): void {
    if (n && o?.id !== n.id) this.fetch()
  }

  // methods // ***
  onSearch() {
    this.fetch()
  }
  fetch() {
    const { fetch: url } = this.urls
    const params = this.queryParams
    this.$store.dispatch('suppliers/fetchBulkUploads', { params, url })
  }
  uploadFiles(formData: FormData) {
    const { upload: url } = this.urls
    const options = { headers: { 'Content-Type': 'multipart/form-data' }, method: 'POST' }
    const axiosOptions = { data: formData }
    api(url, options, axiosOptions)
      .then(() => {
        const message = String(this.$t('ui.uploadBeingProcessed'))
        this.$store.dispatch('core/launchSnackbar', { message })
        this.modalOpen = false
        this.fetch()
      })
      .catch((e: Record<string, any>) => {
        console.error(e)
        const errors = this.$store.getters['core/validationErrors']
        const message = errors.join(', ')
        this.$store.dispatch('core/launchSnackbar', { color: 'error', message })
      })
  }
}


import { Component, Watch, Vue } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import { title } from '@/utilities/filters'
import cloneDeep from 'lodash/cloneDeep'
import chunk from 'lodash/chunk'
import { DEFAULT_DAY_RANGES, DEFAULT_TABLE_OPTIONS, JOURNEY_STATUSES } from '@/utilities/constants'
import { headers, items } from '@/pages/costConfirmation/helpers/index'
import isEqual from 'lodash/isEqual'
import api from '@/utilities/api'
import { downloadDirectToBrowser } from '@/utilities/functions'
import { disableSorting } from '@/utilities/org'

const { AGREED, ALL, DISPUTED, EXPIRED, OPEN } = JOURNEY_STATUSES

const { now } = DateTime

@Component
export default class CostConfirmation extends Vue {
  // data // ***
  dateHasRecentlyChanged = false
  dates: DateTime[] = [now().minus({ days: DEFAULT_DAY_RANGES.COST_CONFIRMATION }), now()]
  disputesModalOpen = false
  failedUpdates: Record<string, boolean> = {}
  initialLoadCompleted = false
  localItems: Record<string, any>[] = []
  options: Record<string, any> = cloneDeep(DEFAULT_TABLE_OPTIONS)
  pendingUpdates: Record<string, boolean> = {}
  searchFields: Record<string, any> = { cmacRef: null, externalRef: null, status: OPEN }
  selectedRows: Record<string, any>[] = []
  successfulUpdates: Record<string, boolean> = {}
  xlsxExportEnabled = true

  // computed // ***
  get actionHeader() {
    return { vueComponent: { name: 'c-icon-with-tooltip', props: { class: 'my-2' } } }
  }
  get activeSuppliers(): Record<string, any>[] {
    return this.$store.getters['suppliers/activeSuppliers'].map((v: number) => this.suppliers[v])
  }
  get activeSupplierIds(): string[] {
    return this.activeSuppliers.map((v: Record<string, any>) => v.id)
  }
  get approvableJourneys() {
    const approvableJourneys: Record<string, any>[] = []
    this.selectedRows.forEach(r => {
      const journey = this.localItems.find((i: Record<string, any>) => i.id === r.id)
      if (journey) approvableJourneys.push(this.getPatchableJourney(journey))
    })
    return approvableJourneys
  }
  get downloading() {
    const { downloadUrl: url } = this
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'GET', url })
  }
  get downloadUrl() {
    return `supplier/costConfirmationJourneys/download`
  }
  get editTextareaWidth() {
    const w = this.$vuetify.breakpoint.width
    if (w > 700) return 600
    else if (w > 600) return 500
    else if (w > 500) return 400
    else if (w > 400) return 300
    else return ''
  }
  get fetchLoading(): boolean {
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'GET', url: this.url })
  }
  get hasSelectedRows(): boolean {
    return !!this.selectedRows.length
  }
  get hasUnsavedChanges() {
    return !!this.updatedRows.length
  }
  get headers(): Record<string, any>[] {
    return disableSorting(headers(this))
  }
  get inApproveState() {
    return !this.inUpdateState && this.hasSelectedRows
  }
  get inUpdateState() {
    return this.hasUnsavedChanges
  }
  get items(): Record<string, any>[] {
    return items(this)
  }
  get journeyPaginationInfo() {
    const j: Record<string, any> = this.$store.getters['suppliers/journeys'] || {}
    return {
      page: (j.pageIndex || 0) + 1,
      returnedRecords: j.returnedRecords || 0,
      total: j.totalRecords || 0,
    }
  }
  get journeys(): Record<string, any>[] {
    return this.$store.getters['suppliers/journeys']?.journeys || []
  }
  get loading(): boolean {
    return this.fetchLoading || this.updateLoading || this.patchLoading
  }
  get mobileBreakpoint(): number {
    return 1270
  }
  get patchLoading(): boolean {
    const payload = { method: 'PATCH', url: this.updateUrl }
    const pending = !!Object.values(this.pendingUpdates).find(v => v === true)
    return this.$store.getters['core/apiEndpointIsLoading'](payload) || pending || this.fetchLoading
  }
  get queryParams() {
    const { dates } = this
    const organisationIds =
      this.activeSuppliers.length >= 1 ? this.activeSupplierIds : [String(this.supplier?.id)]
    const dateFrom = dates[0].toISODate()
    const dateTo = this.dates[1]?.toISODate() || cloneDeep(dates[0]).plus({ days: 1 }).toISODate()
    return {
      CmacRef: this.searchFields.cmacRef,
      CostConfirmationStatus: this.searchFields.status,
      dateFrom,
      dateTo,
      ExternalId: this.searchFields.externalRef,
      organisationIds,
      pageIndex: (this.options.page || 1) - 1,
      pageSize: this.options.itemsPerPage,
    }
  }
  get rowsAreSelectable(): boolean {
    return !this.inUpdateState && this.searchFields.status === OPEN
  }
  get saveButtonText(): string {
    if (this.inUpdateState) return String(this.$t('ui.saveChanges'))
    else if (this.inApproveState) return String(this.$t('ui.approve'))
    else return ''
  }
  get saveCardMessage(): string {
    const upLen = this.updatedRows.length
    const upLenMsg = `${upLen} ${String(this.$tc('plurals.rows', upLen))}`
    const upMsg = `${String(this.$t('ui.updateX', { x: upLenMsg }))}`
    if (this.inUpdateState) return `${upMsg}: `
    else if (this.inApproveState) return `${String(this.$t('ui.approveAllSelectedRows'))}: `
    else return ''
  }
  get searchFieldProps() {
    return {
      cmacRef: { label: this.$t('cmac.cmacRef') },
      externalRef: { label: this.$t('cmac.yourRef') },
      status: {
        items: [
          { text: title(String(this.$t('common.all'))), value: ALL },
          { text: title(String(this.$t('cmac.agreed'))), value: AGREED },
          { text: title(String(this.$t('cmac.disputed'))), value: DISPUTED },
          { text: title(String(this.$t('cmac.expired'))), value: EXPIRED },
          { text: title(String(this.$t('cmac.open'))), value: OPEN },
        ],
      },
    }
  }
  get selectableSelectedRows() {
    const { rowsAreSelectable, selectedRows } = this
    const selectedRowsWithUpdatedRowsRemoved = selectedRows.filter(
      (r: Record<string, any>): boolean => {
        return !this.updatedRows.find((ur: Record<string, any>): boolean => ur.id === r.id)
      }
    )
    return rowsAreSelectable ? selectedRows : selectedRowsWithUpdatedRowsRemoved
  }
  get showActions(): boolean {
    return this.searchFields.status === OPEN
  }
  get showResetButton(): boolean {
    return this.inUpdateState
  }
  get showSaveCard() {
    return this.inApproveState || this.inUpdateState
  }
  get showSelect(): boolean {
    return this.searchFields.status === OPEN
  }
  get supplier(): Record<string, any> {
    return this.$store.getters['suppliers/selectedSupplier']
  }
  get suppliers(): Record<string, any>[] {
    return this.$store.getters['suppliers/suppliers']
  }
  get tableHeaderShouldBeFixed() {
    return this.$vuetify.breakpoint.width >= this.mobileBreakpoint
  }
  get tableItems() {
    return this.localItems.map(i => ({
      ...i,
      isSelectable: this.rowsAreSelectable,
      itemSpecificConfig: {
        ...(i.itemSpecificConfig || {}),
        actions: {
          vueComponent: {
            props: {
              icon: this.getActionIcon(i),
              iconColor: this.getActionIconColor(i),
              tooltip: this.getActionTooltip(i),
            },
          },
        },
      },
    }))
  }
  get updatedRows() {
    const { rowHasChanged } = this
    return this.localItems.filter((i: any) => rowHasChanged(i))
  }
  get updateLoading(): boolean {
    const { updateUrl: url } = this
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'PATCH', url })
  }
  get updateUrl() {
    return 'supplier/costConfirmationJourney'
  }
  get updatableJourneys() {
    return this.updatedRows.map((i: any) => this.getPatchableJourney(i))
  }
  get url() {
    return `supplier/costConfirmationJourneys`
  }

  // created // ***
  created() {
    const { status } = this.$route.query
    if (status && !Array.isArray(status)) this.searchFields.status = Number.parseInt(status)
    this.resetLocalItems()
    if (this.supplier?.id) this.fetch(true)
  }

  // watch // ***
  @Watch('loading')
  onLoadingChanged(v: boolean) {
    if (!v) this.initialLoadCompleted = true
  }
  @Watch('journeys')
  onItemsChanged() {
    this.resetLocalItems()
  }
  @Watch('options.page')
  onPageChanged(n: number, o: number) {
    if (n !== o) this.fetch()
  }
  @Watch('supplier')
  onSupplierChanged(n: Record<string, any> | undefined, o: Record<string, any> | undefined): void {
    if (n && o?.id !== n.id) this.fetch(true)
  }
  @Watch('searchFields.status')
  onStatusChanged() {
    if (this.supplier?.id) this.fetch(true)
  }

  // methods // ***
  async download(): Promise<void> {
    const res = await api(this.downloadUrl, { params: this.queryParams }, { responseType: 'blob' })
    if (res && res.data) downloadDirectToBrowser(res.data, 'export.xlsx')
  }
  fetch(resetPagination = false) {
    if (!this.loading) {
      Vue.set(this, 'selectedRows', [])
      const { url } = this
      this.resetUpdateStatuses()
      if (resetPagination) this.options.page = 1
      this.resetOptions(resetPagination)
      this.$store.dispatch('suppliers/fetchJourneys', { params: this.queryParams, url })
    }
  }
  getActionIcon(j: Record<string, any>) {
    const { id } = j
    if (this.failedUpdates[id]) return 'error'
    else if (this.successfulUpdates[id]) return 'check_circle'
    else if (this.pendingUpdates[id]) return 'pending'
    else return null
  }
  getActionIconColor(j: Record<string, any>) {
    const { id } = j
    if (this.failedUpdates[id]) return 'error'
    else if (this.successfulUpdates[id]) return 'success'
    else if (this.pendingUpdates[id]) return 'warning'
    else return null
  }
  getActionTooltip(j: Record<string, any>) {
    const { id } = j
    if (this.failedUpdates[id]) return this.$t('cmac.updateFailed')
    else if (this.successfulUpdates[id]) return this.$t('cmac.updateSuccessful')
    else if (this.pendingUpdates[id]) return this.$t('cmac.processing')
    else return null
  }
  getLeadMileage(j: any) {
    if (j?.insideCoverage) return -1
    else return 0
  }
  getPatchableJourney(i: Record<string, any>): Record<string, any> {
    return {
      JobEntryId: i.jobNumber.journeyNumber,
      OriginalLeadMileage: i.originalLeadMileage,
      OriginalWaitingTimeMinutes: i.originalWaitingTimeMinutes,
      OriginalParkingExpenseAmount: i.originalParkingExpenseAmount,
      DisputedLeadMileage: Number(i.leadMileage),
      DisputedWaitingTimeMinutes: i.waitingTimeExpenseMinutes,
      DisputedParkingExpenseAmount: i.parking?.cost?.net,
      LeadMileageLimit: i.leadMileageLimit,
      CostConfirmationStatus: i.status,
      Comments: i.comments,
      Expenses: this.getPatchableExpenses(i),
    }
  }
  getPatchableExpenses(i: Record<string, any>): Record<string, any>[] {
    let exp = i.originalExpenses.map((x: any) => {
      let newExp = i.expenses.find((e: any) => e.expenseJobEntryId === x.expenseJobEntryId)
      return {
        expenseJobEntryId: x.expenseJobEntryId,
        expenseTypeId: x.expenseTypeId,
        description: newExp.description,
        originalValue: x.cost.net,
        disputeValue: newExp.cost.net,
      }
    })
    const existingExpenseIds = i.originalExpenses.map((z: any) => z.expenseTypeId)
    i.expenses
      .filter((x: any) => !existingExpenseIds.includes(x.expenseTypeId))
      .forEach((y: any) => {
        exp.push({
          expenseTypeId: y.expenseTypeId,
          description: y.description,
          disputeValue: y.cost.net,
        })
      })
    return exp
  }
  onDatePickerClose() {
    if (this.dateHasRecentlyChanged) this.fetch()
    this.dateHasRecentlyChanged = false
  }
  onDatePickerInput() {
    this.dateHasRecentlyChanged = true
  }
  onPageInput(e: number) {
    if (e !== this.options.page) {
      this.options.page = e
      this.fetch()
    }
  }
  onPageSizeInput(e: number) {
    const currentItemsPerPage = this.options.itemsPerPage
    const currentPage = this.options.page
    if (e !== currentItemsPerPage) {
      this.options.itemsPerPage = e
      if (currentPage === 1) this.fetch()
      else this.options.page = 1
    }
  }
  onReset() {
    this.resetLocalItems()
  }
  onSave() {
    this.updateJourneys()
  }
  onSearch() {
    this.fetch(true)
  }
  onTableCellSaved(payload: Record<string, any> = {}) {
    const { localItems } = this
    const { value, item, header } = payload
    const name = header.value
    let index = localItems.findIndex((i: any) => i.id === item.id)
    if (index === -1) index = localItems.length
    const newItem = cloneDeep(item)
    newItem[name] = value
    Vue.set(localItems, index, newItem)
  }
  resetLocalItems() {
    this.localItems = cloneDeep(this.items)
  }
  resetOptions(resetPagination = false) {
    const newOptions = cloneDeep(DEFAULT_TABLE_OPTIONS)
    const { page, itemsPerPage } = this.options
    newOptions.itemsPerPage = itemsPerPage
    if (!resetPagination) newOptions.page = page
    this.setOptions(newOptions)
  }
  resetUpdateStatuses() {
    Vue.set(this, 'pendingUpdates', {})
    Vue.set(this, 'failedUpdates', {})
    Vue.set(this, 'successfulUpdates', {})
  }
  rowHasChanged(i: any): boolean {
    const referenceRow = this.items.find(r => r.id === i.id) || {}
    const ignoreProperties = ['itemSpecificConfig']
    const changedProperties = []
    Object.keys(referenceRow).forEach((k: string): void => {
      if (ignoreProperties.includes(k)) return
      const oldProp = referenceRow[k]
      const newProp = i[k]
      if (!isEqual(oldProp, newProp)) changedProperties.push({ old: oldProp, key: k, new: newProp })
    })
    return changedProperties.length > 0
  }
  setOptions(o: any) {
    Vue.set(this, 'options', o)
  }
  setFailedUpdate(id: number | string, value: boolean) {
    Vue.set(this.failedUpdates, id, value)
  }
  setPendingUpdate(id: number | string, value: boolean) {
    Vue.set(this.pendingUpdates, id, value)
  }
  setSuccessfulUpdate(id: number | string, value: boolean) {
    Vue.set(this.successfulUpdates, id, value)
  }
  async updateJourney(j: Record<string, any>): Promise<Record<string, any> | void> {
    // j.SupplierUserId = this.supplier?.id
    return api(this.updateUrl, { data: j, json: true, method: 'PATCH' }, undefined)
      .then(() => {
        this.setSuccessfulUpdate(j.JobEntryId, true)
        return j
      })
      .catch((e: any) => {
        this.setFailedUpdate(j.JobEntryId, true)
        throw new Error(e)
      })
      .finally(() => {
        this.setPendingUpdate(j.JobEntryId, false)
      })
  }
  async updateJourneyBatch(journeys: Record<string, any>[]) {
    const pendingJourneys: Promise<Record<string, any> | void>[] = []
    journeys.forEach((j: Record<string, any>) => this.setPendingUpdate(j.JobEntryId, true))
    journeys.forEach((j: Record<string, any>) => pendingJourneys.push(this.updateJourney(j)))
    return Promise.all(pendingJourneys)
      .then(processedJourneys => {
        console.debug('Processed Journeys: ', processedJourneys)
      })
      .catch(e => {
        throw new Error(e)
      })
  }
  async updateJourneys() {
    let journeys: Record<string, any>[] = []
    if (this.inUpdateState) journeys = this.updatableJourneys
    else if (this.inApproveState) journeys = this.approvableJourneys
    this.updateJourneysInBatches(journeys)
  }
  async updateJourneysInBatches(journeys: Record<string, any>[]) {
    const batches = chunk(journeys, 5)
    let failed = false
    for (const batch of batches) {
      await this.updateJourneyBatch(batch).catch(e => {
        console.error('update Journeys error ', e)
        failed = true
      })
    }
    if (!failed) this.fetch(true)
  }
}


import api from '@/utilities/api'
import mapboxgl from 'mapbox-gl'
import { Component, Vue } from 'vue-property-decorator'

import { COORDINATES } from '@/utilities/constants'
const { CENTER, ZOOM } = COORDINATES

@Component
export default class CoverageMap extends Vue {
  loading = false
  journeyData: any = null
  coverageAreaGeometry: any = null
  map: any = null
  mapMarkers: any[] = []
  mapLayers: any[] = []

  get journeyId(): number {
    if (typeof this.$route.query.jobEntryId === 'string')
      return parseInt(this.$route.query.jobEntryId)
    return 0
  }
  get from() {
    if (this.journeyData) return this.journeyData.from
    return {}
  }
  get to() {
    if (this.journeyData) return this.journeyData.to
    return {}
  }
  get vias() {
    if (this.journeyData) return this.journeyData.vias || []
    return []
  }
  get supplier() {
    if (this.journeyData) return this.journeyData.supplier
    return null
  }
  get stops() {
    return [this.from, ...this.vias, this.to]
  }
  get leadMiles() {
    if (!this.journeyData) return ''
    return this.journeyData.leadMilesData.numberOfMiles
  }
  get leadMilesCalculationPoint() {
    if (!this.journeyData) return {}
    return {
      lat: this.journeyData.leadMilesData.lat,
      long: this.journeyData.leadMilesData.long,
    }
  }
  get areaName() {
    if (this.journeyData) return this.journeyData.leadMilesData.areaName
    return ''
  }

  get officeAddress() {
    if (this.journeyData) return this.journeyData.leadMilesData.officeAddress
    return ''
  }

  mounted() {
    this.getLeadMilesData().then(() => {
      this.setupMap()
    })
  }

  setupMap() {
    this.initMap({
      onLoad: () => {
        this.addMapMarkers()
        this.drawLeadMileage()
        this.addCoverageArea()
        this.zoomToMapDetails()
      },
    })
  }
  initMap(options: any) {
    let mapOptions = {
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: ZOOM,
      center: CENTER,
    }
    this.map = new mapboxgl.Map(mapOptions)
    this.map?.on('load', async () => {
      options.onLoad()
    })
  }
  addMapMarkers() {
    this.addMapMarker('map_man', this.from, `Pick up: ${this.from.address1}, ${this.from.postCode}`)
    if (!this.coverageAreaGeometry) {
      this.addMapMarker('map_man', this.leadMilesCalculationPoint, `Office: ${this.officeAddress}`)
    } else {
      this.addMapMarker('map_man', this.leadMilesCalculationPoint, `Edge of Coverage Area`)
    }
  }
  addMapMarker(icon: string, p: any, popupText: string) {
    const markerIcon = document.createElement('div')
    markerIcon.setAttribute(
      'style',
      `width: 32px; height: 32px; top: -15px; background-size: cover; cursor: pointer; background-image: url('/images/map/${icon}.png')`
    )
    const iconMarker = new mapboxgl.Marker({ element: markerIcon }).setLngLat([
      p.long || p.lng,
      p.lat,
    ])
    if (popupText) {
      const popup = new mapboxgl.Popup({ offset: 5, className: 'mapbox-popup' }).setHTML(popupText)
      iconMarker.setPopup(popup)
    }
    iconMarker.addTo(this.map)
    this.mapMarkers.push(iconMarker)

    return iconMarker
  }
  drawLeadMileage() {
    let roadRouteGeometry = JSON.parse(this.journeyData.leadMilesData.roadRouteGeoJsonString)

    const routeGeoJson = {
      type: 'Feature',
      properties: {},
      geometry: roadRouteGeometry,
    }

    this.drawGeoJsonRoute('leadmileage', routeGeoJson, '#cc0000')
  }
  addCoverageArea() {
    if (!this.coverageAreaGeometry) return
    this.coverageAreaGeometry.features.forEach((f: any) => {
      this.drawGeoJsonLayer(
        `${this.areaName}`,
        {
          type: f.type,
          geometry: {
            type: f.geometry.type,
            coordinates: f.geometry.coordinates,
          },
        },
        {}
      )
    })
  }
  drawGeoJsonRoute(routeLayerId: string, routeGeoJson: any, color = '#112138') {
    this.map.addLayer({
      id: routeLayerId,
      type: 'line',
      source: {
        type: 'geojson',
        data: routeGeoJson,
      },
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': color,
        'line-width': 5,
        'line-opacity': 0.75,
      },
    })
    this.mapLayers.push(routeLayerId)
  }
  drawGeoJsonLayer(layerId: string, mapData: any, options: any) {
    const opts = Object.assign(
      {
        popupText: layerId,
        onClick: null,
      },
      options
    )

    this.map.addSource(layerId, {
      type: 'geojson',
      data: mapData,
    })
    this.map.addLayer({
      id: layerId,
      type: 'fill',
      source: layerId,
      layout: {},
      paint: {
        'fill-color': options.layerColour || '#0080ff',
        'fill-opacity': 0.3,
      },
    })
    this.map.on('click', layerId, (e: any) => {
      if (opts.popupText) {
        new mapboxgl.Popup().setLngLat(e.lngLat).setHTML(opts.popupText).addTo(this.map)
      }
      if (opts.onClick) {
        opts.onClick(layerId, mapData, e)
      }
    })
    this.mapLayers.push(layerId)
    this.map.addLayer({
      id: layerId + 'outline',
      type: 'line',
      source: layerId,
      layout: {},
      paint: {
        'line-color': '#000',
        'line-width': 1,
      },
    })
    this.mapLayers.push(layerId + 'outline')
  }
  zoomToMapDetails() {
    this.fitToRouteAndPolygons(
      [this.from, this.leadMilesCalculationPoint],
      this.coverageAreaGeometry,
      { padding: 50 }
    )
  }
  fitToBounds(coords: any[], options: any) {
    if (!coords || !coords.length) return
    const bounds = new mapboxgl.LngLatBounds()
    coords.forEach(x => {
      bounds.extend([x[0], x[1]])
    })
    this.map.fitBounds(bounds, {
      duration: options?.duration || 500,
      padding: options?.padding || 140,
      maxZoom: options?.maxZoom || 14,
    })
  }
  fitToRouteAndPolygons(stops: any[], polygons: any[], options: any) {
    this.fitToBounds([...this.getStopsCoords(stops), ...this.getPolygonCoords(polygons)], options)
  }
  getStopsCoords(stops: any[]) {
    if (!stops || !stops.length) return []
    let coords: any[] = []
    stops.forEach((x: any) => {
      coords.push([x.long, x.lat])
    })
    return coords
  }
  getPolygonCoords(polygons: any[]) {
    if (!polygons) return []
    if (!polygons.length) polygons = [polygons]
    let coords: any[] = []
    polygons.forEach((p: any) => {
      p.features.forEach((f: any) => {
        let geoCoords =
          f.geometry.type === 'MultiPolygon' ? f.geometry.coordinates : f.geometry.coordinates[0]

        geoCoords.forEach((x: any) => {
          coords.push([x[0], x[1]])
        })
      })
    })
    return coords
  }
  getLeadMilesData() {
    this.loading = true
    let request = {
      jobEntryId: this.journeyId,
    }
    return api(
      '/SupplierCostConfirmationDispute/LeadMilesData',
      { data: request, json: true, method: 'POST' },
      undefined
    )
      .then(response => {
        this.journeyData = response.data
        this.coverageAreaGeometry = this.journeyData.leadMilesData.coverageAreaGeoJsonString
          ? JSON.parse(this.journeyData.leadMilesData.coverageAreaGeoJsonString)
          : null
      })
      .finally(() => {
        this.loading = false
      })
  }
}

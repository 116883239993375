import { EXPENSE_TYPES, JOURNEY_STATUSES } from '@/utilities/constants'
import { mustNotExceedLeadMileageLimit } from '@/utilities/validations'
import cloneDeep from 'lodash/cloneDeep'

const { MEET_AND_GREET, PARKING, TOLL, WAITING_TIME, CLEANING, MISCELLANEOUS, GREEN_FEE } =
  EXPENSE_TYPES

const collectedExpenses = [MEET_AND_GREET, PARKING, TOLL, CLEANING, MISCELLANEOUS, GREEN_FEE]

const { OPEN, DISPUTED } = JOURNEY_STATUSES

const defaultItems = (context: Record<string, any>): Record<string, any>[] => {
  const journeys = cloneDeep(context.journeys)
  const getExpense = (
    e: Record<string, any>[] = [],
    id: number,
    currency: number
  ): Record<string, any> | null => {
    const expense = e.find(i => i.expenseTypeId === id)
    if (!expense) {
      return {
        cost: {
          net: 0,
          currency: currency,
        },
      }
    } else {
      return expense
    }
  }
  const getExpenses = (
    e: Record<string, any>[] = [],
    currency: number
  ): Record<string, any> | null[] => {
    return collectedExpenses.map(x => getExpense(e, x, currency)).filter(y => y)
  }
  const getWaitingTimeExpenseMinutes = (w: number) => (!w ? 0 : Math.floor(Number(w)))
  const getTotal = (j: Record<string, any>) => {
    let expenseTotal = 0
    if (j.expenses && j.expenses.length > 0) {
      expenseTotal = j.expenses.reduce((accumulator: any, e: any) => {
        return accumulator + e.cost?.net
      }, 0)
    }
    const x =
      j.leadMileageCost &&
      j.leadMileageCost.net > 0 &&
      j.supplierCostConfirmationStatus !== DISPUTED
        ? j.leadMileageCost.net
        : 0
    return j.costNet + expenseTotal + x
  }
  return journeys.map((j: Record<string, any>) => ({
    ...j,
    cancelled: !!j.isCancelled,
    departureDateTime: j.departureDateOffset,
    fare: { currency: j.currency, fare: j.costNet },
    greenFee: getExpense(j.expenses, GREEN_FEE, j.currency),
    itemSpecificConfig: {
      leadMileage: {
        rules: [(v: any) => mustNotExceedLeadMileageLimit(v, j.leadMileageLimit)],
        editable: !!j.canAddLeadMileage && j.supplierCostConfirmationStatus === OPEN,
        vueComponent: {
          props: {
            canAddLeadMileage: j.canAddLeadMileage,
            leadMileageLimit: j.leadMileageLimit,
            jobEntryId: j.id,
          },
        },
      },
      id: {
        vueComponent: {
          props: {
            jobEntryId: j.id,
            showComponent:
              j.canAddLeadMileage &&
              j.supplierCostConfirmationStatus === OPEN &&
              j.leadMileageCalculationType === 1,
          },
        },
      },
      jobNumber: {
        vueComponent: {
          props: {
            copyValue: !j.externalId && j.externalId !== 0 ? undefined : String(j.externalId),
          },
        },
      },
      waypoints: {
        vueComponent: {
          props: {
            waypoints: j.waypoints,
          },
        },
      },
    },
    jobNumber: { externalId: j.externalId, jobNumber: j.jobId, journeyNumber: j.id },
    originalLeadMileage: context.getLeadMileage(j),
    originalWaitingTimeMinutes: getWaitingTimeExpenseMinutes(j.waitingTimeExpenseMinutes),
    originalParkingExpenseAmount: getExpense(j.expenses, PARKING, j.currency)?.cost?.net,
    status: j.supplierCostConfirmationStatus,
    waitingTimeExpenseMinutes: getWaitingTimeExpenseMinutes(j.waitingTimeExpenseMinutes),
    waitingTimeCost: getExpense(j.expenses, WAITING_TIME, j.currency),
    vehicleName: j.vehicleDescription,
    total: { currency: j.currency, total: getTotal(j) },
    originalExpenses: getExpenses(j.expenses, j.currency),
    expenses: cloneDeep(getExpenses(j.expenses, j.currency)),
  }))
}

export const items = (context: Record<string, any>): Record<string, any>[] => {
  switch (context.searchFields.status) {
    case OPEN:
      return openItems(context)
    default:
      return defaultItems(context)
  }
}

const openItems = (context: Record<string, any>): Record<string, any>[] => {
  return defaultItems(context)
}

import { disableSorting, getFormattedDateTime } from '@/utilities/org'
import cloneDeep from 'lodash/cloneDeep'
import i18n from '@/plugins/i18n'
import { title } from '@/utilities/filters'

export const headers = (context: Record<string, any>): Record<string, any>[] => {
  return disableSorting([
    {
      text: title(String(context.$t('common.date')) + ' ' + String(context.$t('common.uploaded'))),
      value: 'dateUploadedUtc',
    },
    { text: title(String(context.$t('common.user'))), value: 'uploadedBy' },
    { text: title(String(context.$t('common.file'))), value: 'fileName' },
    { text: title(String(context.$t('ui.uploadStatus'))), value: 'uploadStatus' },
    { text: title(String(context.$t('common.total'))), value: 'totalNumberOfRecords' },
    { text: title(String(context.$t('common.processed'))), value: 'numberOfRecordsProcessed' },
    { text: title(String(context.$t('common.accepted'))), value: 'numberOfRecordsAccepted' },
    { text: title(String(context.$t('common.disputed'))), value: 'numberOfRecordsDisputed' },
    { text: title(String(context.$t('common.failed'))), value: 'numberOfRecordsFailed' },
    {
      text: title(String(context.$t('common.previouslyUploaded'))),
      value: 'numberOfRecordsAlreadyProcessed',
    },
  ])
}
export const items = (context: Record<string, any>): Record<string, any>[] => {
  const bulkUploads = cloneDeep(context.bulkUploads)
  return bulkUploads.map((i: Record<string, any>) => ({
    ...i,
    itemSpecificConfig: {
      ...(i.itemSpecificConfig || {}),
      actions: {
        vueComponent: { props: { loading: i.uploadStatus === BULK_UPLOAD_STATUSES.PROCESSING } },
      },
    },
    dateUploadedUtc: getFormattedDateTime(i.dateUploadedUtc),
    uploadStatus: getUploadStatus(i.uploadStatus),
  }))
}

export const BULK_UPLOAD_STATUSES = Object.freeze({
  PROCESSING: 0,
  COMPLETED: 1,
})

export const getUploadStatus = (s: number): string => {
  switch (s) {
    case BULK_UPLOAD_STATUSES.PROCESSING:
      return String(i18n.t('common.processing'))
    case BULK_UPLOAD_STATUSES.COMPLETED:
      return String(i18n.t('common.completed'))
    default:
      return ''
  }
}
